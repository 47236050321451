var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container", attrs: { id: "god-index" } },
    [
      _vm.godInfo
        ? [
            _c("div", { staticClass: "index-out-box" }, [
              ["jade_emperor", "yu_chan_cai_shen"].includes(_vm.godInfo.index)
                ? _c("img", {
                    staticClass: "god-title-img",
                    attrs: { src: _vm.godInfo.title_img },
                  })
                : _vm._e(),
              _c("img", {
                staticClass: "desk",
                attrs: {
                  src:
                    _vm.godInfo.wap_desk_image || _vm.defaultImg.wap_desk_image,
                },
              }),
              _c(
                "div",
                { staticClass: "index-box" },
                [
                  _vm.godInfo.index === "jade_emperor"
                    ? [
                        _c("img", {
                          staticClass: "weilan-img",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/41de845b601188-750x135.png",
                          },
                        }),
                        _c("img", {
                          staticClass: "logo-img",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/2f197e62357e0e-118x114.png",
                          },
                          on: { click: _vm.jump },
                        }),
                        _c("img", {
                          staticClass: "left-bird",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/abec33f6d12ffe-132x362.png",
                          },
                        }),
                        _c("img", {
                          staticClass: "right-bird",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/8b39866eaaf9b5-132x362.png",
                          },
                        }),
                        _c("img", {
                          staticClass: "god-cover-img",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/8ad78493251219-568x904.png",
                          },
                        }),
                        _c("div", {
                          staticClass: "left-zhuzi-svga",
                          attrs: { id: `left-zhuzi-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "right-zhuzi-svga",
                          attrs: { id: `right-zhuzi-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "god-svga",
                          attrs: { id: `god-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "lizi-svga",
                          attrs: { id: `lizi-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "top-yun-svga",
                          attrs: { id: `top-yun-svga-${_vm.godId}` },
                          on: {
                            click: function ($event) {
                              return _vm.openPopup("SendGodRef")
                            },
                          },
                        }),
                        _c("div", {
                          staticClass: "bottom-yun-svga",
                          attrs: { id: `bottom-yun-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "left-bird-svga",
                          attrs: { id: `left-bird-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "right-bird-svga",
                          attrs: { id: `right-bird-svga-${_vm.godId}` },
                        }),
                        _c("div", {
                          staticClass: "circle-svga",
                          attrs: { id: `circle-svga-${_vm.godId}` },
                        }),
                      ]
                    : _vm._e(),
                  _vm.emptyIndex
                    ? _c("img", {
                        staticClass: "god-img",
                        attrs: {
                          src:
                            _vm.indexMode === "western"
                              ? "https://img-fe.tengzhihh.com/image/eb850b70884c6bfc27601cd218af2fb0.png"
                              : "https://img-fe.tengzhihh.com/image/42b0cdfbafa94e-568x904.png",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$utils.checkAuth(
                              _vm.serialId,
                              _vm.inviteGod
                            )
                          },
                        },
                      })
                    : _vm.godInfo.index === "yu_chan_cai_shen"
                    ? [
                        _c("div", {
                          attrs: { id: "yu_chan_cai_shen" },
                          on: {
                            click: function ($event) {
                              return _vm.openPopup("SendGodRef")
                            },
                          },
                        }),
                        _c("img", {
                          staticClass: "ycgcs-left",
                          attrs: {
                            src: "https://fe-img.tengzhihh.com/image/6862044f-dc85-480c-bd7c-e95d0aeebf25.png",
                          },
                        }),
                        _c("img", {
                          staticClass: "ycgcs-right",
                          attrs: {
                            src: "https://fe-img.tengzhihh.com/image/3973e370-bb32-4d32-bcc0-debc86285fcf.png",
                          },
                        }),
                      ]
                    : _vm.godInfo.index === "shui_yue_guan_yi"
                    ? [
                        _c("div", {
                          attrs: { id: "shui_yue_guan_yi" },
                          on: {
                            click: function ($event) {
                              return _vm.openPopup("SendGodRef")
                            },
                          },
                        }),
                        _c("img", {
                          staticClass: "sygy-left",
                          attrs: {
                            src: "https://fe-img.tengzhihh.com/image/5c4671d2e1c8e56bdf0190cea3168374-70x376.png",
                          },
                        }),
                        _c("img", {
                          staticClass: "sygy-right",
                          attrs: {
                            src: "https://fe-img.tengzhihh.com/image/17dd6199cdfe438e013483798da8b1f1-70x376.png",
                          },
                        }),
                      ]
                    : _c("img", {
                        staticClass: "god-img",
                        attrs: { src: _vm.godInfo.wap_detail_image },
                        on: {
                          click: function ($event) {
                            return _vm.openPopup("SendGodRef")
                          },
                        },
                      }),
                  _vm.activeIndex !== 0
                    ? _c("img", {
                        staticClass: "arrow arrow-left",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.updateIndex("left")
                          },
                        },
                      })
                    : _vm._e(),
                  !_vm.emptyIndex
                    ? _c("img", {
                        staticClass: "arrow arrow-right",
                        attrs: {
                          src: "https://img-fe.tengzhihh.com/image/b0c85ea7f1ba40-72x72.png",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.updateIndex("right")
                          },
                        },
                      })
                    : _vm._e(),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showYan,
                        expression: "showYan",
                      },
                    ],
                    staticClass: "yan-svga",
                    attrs: { id: `yan-svga-${_vm.godId}` },
                  }),
                  _c("div", { staticClass: "offerings-box" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item hua left",
                      attrs: { src: _vm.flower.offer_left_image },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item hua right",
                      attrs: { src: _vm.flower.offer_right_image },
                    }),
                    _vm.indexMode !== "western"
                      ? _c("img", {
                          directives: [
                            {
                              name: "debounce",
                              rawName: "v-debounce",
                              value: _vm.touchOffering,
                              expression: "touchOffering",
                            },
                          ],
                          staticClass: "offering-item xiang",
                          attrs: { src: _vm.incense.offer_left_image },
                        })
                      : _vm._e(),
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item shuiguo left",
                      attrs: { src: _vm.fruit.offer_left_image },
                    }),
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item shuiguo right",
                      attrs: { src: _vm.fruit.offer_left_image },
                    }),
                    !_vm.emptyIndex && !_vm.emptyOfferings
                      ? _c("img", {
                          staticClass: "fire-ani fire-left",
                          attrs: { src: _vm.fireImg },
                        })
                      : _vm._e(),
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item lazhu left",
                      attrs: { src: _vm.candle.offer_left_image },
                    }),
                    !_vm.emptyIndex && !_vm.emptyOfferings
                      ? _c("img", {
                          staticClass: "fire-ani fire-right",
                          attrs: { src: _vm.fireImg },
                        })
                      : _vm._e(),
                    _c("img", {
                      directives: [
                        {
                          name: "debounce",
                          rawName: "v-debounce",
                          value: _vm.touchOffering,
                          expression: "touchOffering",
                        },
                      ],
                      staticClass: "offering-item lazhu right",
                      attrs: { src: _vm.candle.offer_right_image },
                    }),
                  ]),
                  _vm.$utils.calcUserIsMasterWithSerialId(_vm.serialId)
                    ? _c(
                        "div",
                        {
                          staticClass: "fude f fc",
                          on: { click: _vm.openFude },
                        },
                        [
                          _c("img", {
                            staticClass: "fude-img",
                            attrs: {
                              src: "https://img-fe.tengzhihh.com/image/83bba5260be65e-50x50.png",
                            },
                          }),
                          _c("div", { staticClass: "fude-text" }, [
                            _vm._v(
                              "香火" + _vm._s(_vm.godInfo.user_god_fude || 0)
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  !_vm.emptyIndex
                    ? _c("div", { staticClass: "buttonList f" }, [
                        _vm.indexMode !== "western"
                          ? _c("img", {
                              directives: [
                                {
                                  name: "debounce",
                                  rawName: "v-debounce",
                                  value: _vm.handleIncense,
                                  expression: "handleIncense",
                                },
                              ],
                              staticClass: "incense-image",
                              attrs: {
                                src: "https://img-fe.tengzhihh.com/image/62b7cc5a2cd474b62c6b1a27b7b93a68-200x94.png",
                              },
                            })
                          : _vm._e(),
                        _c("img", {
                          directives: [
                            {
                              name: "debounce",
                              rawName: "v-debounce",
                              value: _vm.touchOffering,
                              expression: "touchOffering",
                            },
                          ],
                          staticClass: "Offering-image",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/91fb9570abad2837bf5d8adeb09a3d59-200x94.png",
                          },
                        }),
                        _c("img", {
                          directives: [
                            {
                              name: "debounce",
                              rawName: "v-debounce",
                              value: _vm.praywish,
                              expression: "praywish",
                            },
                          ],
                          staticClass: "wish-image",
                          attrs: {
                            src: "https://img-fe.tengzhihh.com/image/2ac854114be8cd8f950275a350353e68-200x94.png",
                          },
                        }),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "main-btn-list f" }, [
                    !_vm.emptyIndex && !_vm.isDefaultGod
                      ? _c(
                          "div",
                          {
                            class: `set-main-btn ${
                              _vm.indexMode === "western"
                                ? "set-main-btn-western"
                                : ""
                            }`,
                            on: {
                              click: function ($event) {
                                return _vm.$utils.checkAuth(
                                  _vm.serialId,
                                  _vm.setDefaultGod
                                )
                              },
                            },
                          },
                          [_vm._v(" 设为主位 ")]
                        )
                      : _vm._e(),
                    !_vm.emptyIndex
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "debounce",
                                rawName: "v-debounce",
                                value: _vm.autoOffering,
                                expression: "autoOffering",
                              },
                            ],
                            class: `set-main-btn ${
                              _vm.indexMode === "western"
                                ? "set-main-btn-western"
                                : ""
                            }`,
                          },
                          [_vm._v(" 一键供奉 ")]
                        )
                      : _vm._e(),
                  ]),
                  _vm.godData &&
                  _vm.godData.worden_fish_icon &&
                  !["jade_emperor", "yu_chan_cai_shen"].includes(
                    _vm.godInfo.index
                  )
                    ? _c("img", {
                        staticClass: "woodfish",
                        attrs: { src: _vm.godData.worden_fish_icon, alt: "" },
                        on: { click: _vm.woodfishFn },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ]
        : _vm._e(),
      _c("Dialog", { ref: "dialog" }),
      _c("OfferingsTimeout", {
        ref: "OfferingsTimeoutRef",
        attrs: { offerings: _vm.offerings, time: _vm.calcExpireTime },
      }),
      _vm.godInfo
        ? _c("Fude", {
            ref: "FudeRef",
            attrs: {
              godId: _vm.godId,
              godFude: _vm.godInfo.user_god_fude,
              godName: _vm.godInfo.name,
              todayTask: _vm.todayTask,
            },
            on: { goOffer: _vm.goOffer, viewFude: _vm.viewFude },
          })
        : _vm._e(),
      _c(
        "van-overlay",
        {
          attrs: {
            show: _vm.godInfo.status === 2,
            "lock-scroll": false,
            "class-name": "cus-overlay",
          },
          nativeOn: {
            scroll: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("div", { staticClass: "send-expireInterval f fc fvc" }, [
            _c("span", [_vm._v("距离送神")]),
            _c("span", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.calcSendGodTime)),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "op-btn",
              on: {
                click: function ($event) {
                  return _vm.$utils.checkAuth(_vm.serialId, _vm.cancelSendGod)
                },
              },
            },
            [_vm._v("取消送神上天")]
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }